import { useBoundStore } from 'store/useBoundStore'
import { useEventCallback } from 'utils/hooks/useEventCallback'
import {
  generateEventTracker,
  generateViewTracker,
} from '../utils/generateTracker'

export type HandleEventTracker = {
  eventName: string
  parameters?: unknown
}

export function useSendTracker() {
  const handleScreenTracker = useEventCallback(
    ({ screenName }: { screenName: string }) => {
      const message = generateViewTracker({
        parameters: { screen_name: screenName, screen_class: screenName },
      })
      useBoundStore.getState().sendMessage(message)
    },
  )

  const handleEventTracker = useEventCallback(
    ({ eventName, parameters }: HandleEventTracker) => {
      const message = generateEventTracker({ eventName, parameters })
      useBoundStore.getState().sendMessage(message)
    },
  )

  return { handleScreenTracker, handleEventTracker }
}
