import { sleep } from '@paladise/utils/sleep'
import React from 'react'
import { useEventCallback } from './useEventCallback'

const noop = () => {}

export type WebSocketOptions = {
  onOpen?: (event: WebSocketEventMap['open']) => void
  onClose?: (event: WebSocketEventMap['close']) => void
  onError?: (event: WebSocketEventMap['error']) => void
  onMessage?: (event: WebSocketEventMap['message']) => void
}

export type SendWebSocketData = (
  data: string | ArrayBufferLike | Blob | ArrayBufferView,
) => void

const DEFAULT_OPTIONS = {
  onOpen: noop,
  onClose: noop,
  onError: noop,
  onMessage: noop,
}

export const useWebsocket = (
  url: string,
  token?: string,
  options: WebSocketOptions = DEFAULT_OPTIONS,
) => {
  const [ws, setWs] = React.useState<WebSocket | null>(null)
  const messageQueueRef = React.useRef<
    Array<string | ArrayBufferLike | Blob | ArrayBufferView>
  >([])

  const onOpen = useEventCallback(options.onOpen || noop)
  const onClose = useEventCallback(options.onClose || noop)
  const onError = useEventCallback(options.onError || noop)
  const onMessage = useEventCallback(options.onMessage || noop)

  const sendMessage = React.useCallback(
    (data: string | ArrayBufferLike | Blob | ArrayBufferView) => {
      if (ws) {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(data)
        } else {
          messageQueueRef.current.push(data)
        }
      }
    },
    [ws],
  )

  React.useEffect(() => {
    let canceled = false

    let newWs: WebSocket | null = null
    async function createWebSocket(retryCount = 0, initialTimeout = 0) {
      await sleep(initialTimeout)
      if (canceled) return

      try {
        newWs = token
          ? new WebSocket(url, ['Authorization', token])
          : new WebSocket(url)
        newWs.onopen = event => {
          onOpen(event)

          while (messageQueueRef.current.length > 0) {
            const message = messageQueueRef.current.shift()
            if (message) newWs?.send(message)
          }
        }
        newWs.onclose = event => {
          if (canceled) return
          onClose(event)

          if (retryCount < 3) {
            createWebSocket(retryCount + 1, 200)
          }
        }
        newWs.onerror = event => {
          onError(event)
        }
        newWs.onmessage = event => {
          onMessage(event)
        }
        setWs(newWs)
      } catch (error) {
        console.error('Failed to create websocket', error)
      }
    }

    createWebSocket()

    return () => {
      canceled = true
      if (newWs) {
        newWs.close()
      }
    }
  }, [onClose, onError, onMessage, onOpen, token, url])

  return [ws, sendMessage] as const
}
