'use client'

import { useEffect, useRef } from 'react'
import { env } from 'store/server-context/static'

import { useBoundStore } from 'store/useBoundStore'
import { useWebsocket } from 'utils/hooks/useWebsocket'

export const TrackerInitializer = ({
  sessionId,
  v2Token,
}: {
  sessionId: string
  v2Token: string
}) => {
  const [ws, sendMessage] = useWebsocket(env.HOST_TRACKER, v2Token)
  const initialRef = useRef(false)

  useEffect(() => {
    if (!initialRef.current && ws) {
      useBoundStore.setState({
        ws,
        sendMessage,
        sessionId,
      })
      initialRef.current = true
    }
  }, [ws, sessionId, sendMessage])

  return null
}
