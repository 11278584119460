import { create } from 'zustand'

import {
  createTrackerSlice,
  type TrackerAction,
  type TrackerState,
} from './slice/trackerSlice'

type StoreState = TrackerState
type StoreActions = TrackerAction

export const useBoundStore = create<StoreState & StoreActions>()(
  createTrackerSlice,
)
