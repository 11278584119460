import type { TRACKER_EVENT_NAME } from '@paladise/tracker/types/tracker'
import { session } from 'store/server-context/static'
import { useBoundStore } from 'store/useBoundStore'
import { SCREEN_EVENT_NAME } from '../constant/trackerScreenConstant'

/**
 * Note:
 * tracker event name generator logic document
 * https://playsee.atlassian.net/wiki/spaces/~712020653d38745c4e4d3cb00136950d876daa/pages/301727755/Session+Trackers+From+App+Team
 *  */

export const generateEventName = ({
  type,
  text,
  action,
}: TRACKER_EVENT_NAME) => {
  return `${type}_${text}_${action}`
}

export function generateEventTracker({
  eventName,
  parameters,
}: {
  eventName: string
  parameters?: unknown
}): string {
  const user_id = session.user_id
  const session_id = useBoundStore.getState().sessionId

  const tracker = {
    event_name: eventName,
    session_id,
    user_id,
    ...(parameters ? { parameters } : {}),
  }

  return JSON.stringify(tracker)
}

export function generateViewTracker({
  parameters,
}: {
  parameters: {
    screen_name: string
    screen_class: string
  }
}): string {
  const user_id = session.user_id
  const session_id = useBoundStore.getState().sessionId

  const tracker = {
    event_name: SCREEN_EVENT_NAME,
    session_id,
    user_id,
    parameters,
  }

  return JSON.stringify(tracker)
}
